<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <select v-model="departement" class="form-select mb-3" style="width: 100%;" required v-on:change="changeDept($event)">
                    <option class="w3-white" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
            </div>
            <div class="col-md-6">
                <button class="btn btn-danger" @click="showUn()">Unapproved</button>
                <button class="btn btn-success ms-3" @click="showAdd()">Approved</button>
            </div>
        </div>

        <div class="container-fluid pt-1 scrlt-y" v-bind:style="'height:'+  (screenHeight-135) +'px;'">
            <div class="row m-0">
                <div class="mb-2 col-md-9 col-12 m-0 p-0">
                    <div class="row container">
                        <div class="col-md-3 col-6 mb-3">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Customer</p>
                                    <p class="h8 fw-bold">{{card.customer}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-account-multiple-outline"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 ">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Supplier</p>
                                    <p class="h8 fw-bold">{{card.supplier}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-truck-cargo-container"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-12">
                                    <p class="h9 fw-bold mb-0">Invoice tgl {{day}}</p>
                                    <p class="h8 fw-bold">{{card.invoiceperday}} / {{card.amountinvoiceperday}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-file-chart-outline"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3">
                                <div class="col-md-9">
                                    <p class="h9 fw-bold mb-0">Invoice {{month}}</p>
                                    <p class="h8 fw-bold">{{card.invoicepermonth}}</p>
                                    <p class="text-center h2 text-black-50">
                                        <span class="mdi mdi-file-chart-outline"></span>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3 overflow-hidden">
                                <div class="col-md-10">
                                    <p class="h9">Sales Order</p>
                                    <p class="h4 fw-bold">{{card.so | toRp}}</p>
                                </div>
                                <p class="text-center h1 text-black-50">
                                    <span class="mdi mdi-account-cash-outline"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ">
                        <div class="card bg-light bg-gradient rounded  overflow-hidden">
                            <div class=" row m-2 mt-3 overflow-hidden">
                                <div class="col-md-10">
                                    <p class="h9">Sales Invoice</p>
                                    <p class="h4 fw-bold">{{card.si | toRp}}</p>
                                </div>
                                <p class="text-center h1 text-black-50">
                                    <span class="mdi mdi-cash-check"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card rounded bg-light bg-gradient p-0">
                            <div class="card-header bg-transparent">
                                <p class="h8 fw-bold text-center mt-2">INVOICE</p>
                            </div>
                            <div class="p-5">
                                <line-chart :chartData="lineData" :options="lineOptions" ></line-chart>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
                <div class="col-md-3 col-12">
                <div class="col-md-12">
                    <div class="card p-3 bg-light bg-gradient">
                        <div class="card-header bg-transparent">
                            <p class="h9 fw-bold text-uppercase">5 BEST SELLING ITEMS {{monthSub}}</p>
                        </div>
                        <div class="pt-3">
                                <pie-chart class="mb-3" :chartData="chartData" :options="chartOptions" :height="500"></pie-chart>
                                    <ul class="list-group">
                                        <li class="h9 list-group-item d-flex justify-content-between align-items-center" v-for="item in recCharts" :key="item.tm_id">
                                            {{ item.kategori }}
                                            <span class="badge bg-secondary">{{ item.qty }}</span>
                                            <span class="badge bg-primary rounded-pill">Rp.{{ item.total | toRp }}</span>
                                        </li>
                                    </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <!-- modal -->
            <div id="modal-un" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1" style="max-width: 600px;max-height:650px;">
                <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div class="w3-animate-zoom modal-content col-md-3">
                        <div class="modal-header pb-0">
                            <button onclick="document.getElementById('modal-un').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Approved</p>
                        </div>
                        <div class="modal-body">
                            <form class="container mb-3" id="cekUn" v-on:submit.prevent="cekunUp" autocomplete="off">
                                <label for="ta_so" class="form-label h9 fw-bold">So :</label>
                                    <div class="input-group">
                                        <input ref="ta_so" type="text" class="form-control" id="ta_so" required v-model="ren.ta_so">
                                        <button form="cekUn" class="input-group-text btn btn-warning" type="submit"> Cek</button>
                                    </div>
                            </form>
                            <form action="" class="container d-none" id="dtDetail">
                                <div class="">
                                    <p class="">Nomer SO : <b>{{rens.TrNo}}</b></p>
                                    <p class="">Nama Customer : <b>{{rens.Custname}}</b></p>
                                    <p class="">Keterangan : <b v-if="rens.Reason">{{ rens.Reason }}</b><b v-else>data kosong</b></p>
                                    <p class="">Status Invoice :  <b><i class="fa" :class="{'fa-check': rens.statusSi !== 1, 'fa-times': rens.statusSi === 1}"></i></b></p>
                                    <p class="">Status Surat Jalan :  <b><i class="fa" :class="{'fa-check': rens.statusSj !== 1, 'fa-times': rens.statusSj === 1}"></i></b></p>
                                </div>
                            </form>

                        </div>
                        <div class="modal-footer"  style="margin-top: 15px;">
                            <h6 class="w3-right">
                            <button :disabled="rens.statusSj === 1" id="unapin" class="btn btn-warning rounded" type="submit" @click="unAp"><i class="fa fa-save"></i> Unapprove</button>
                            <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-un').style.display='none'"><i class="fa fa-close"></i> Close</button>
                            </h6>
                        </div>
                        </div>
                </div>
            </div>

            <div id="modal-add" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1" style="max-width: 600px;max-height:650px;">
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="w3-animate-zoom modal-content col-md-3">
                        <div class="modal-header pb-0">
                            <button onclick="document.getElementById('modal-add').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Approved || {{rex.cust}}</p>
                        </div>
                        <div class="modal-body">
                            <p class="fw-bold h9">
                                Masukan Nomer SO :
                            </p>
                            <form class="container d-flex mb-4" id="frmDo" v-on:submit.prevent="ceks" autocomplete="off">
                                <input type="text" class="form-control" id="td_so" v-model="red.td_so" placeholder="isikan nomer so">
                                <input type="submit" class="btn btn-warning ms-3" value="cek">
                              </form>
                            <div class="d-none container" id="dtDtl">
                                <form class="mb-3" id="frmAprove" v-on:submit.prevent="saveData" autocomplete="off">
                                    <input type="hidden" class="form-control" id="td_so" v-model="rex.sys" placeholder="isikan nomer so">
                                    <p class="fw-bold h9">
                                        Reason
                                    </p>
                                    <input type="text" class="form-control" id="td_so" v-model="rex.reason" placeholder="Approved Reason">
                                </form>
                                <div class="mb-3 ps-2">
                                    <p class="">Nama Customer : <b>{{rex.cust}}</b></p>
                                    <p class="">Total Penjualan : <b>Rp {{rex.total | toRp}}</b></p>
                                    <p class="">Tanggal Pengajuan : <b>{{rex.dates}}</b></p>
                                    <p class="">Tempo : <b>{{rex.topid}}</b></p>
                                    <p class="">status : <span :class="rex.app == 1 ? 'badge bg-success' : 'badge bg-warning'"> {{ rex.app == 1 ? 'Approved' : 'Belum Approved' }} </span> <b v-if="rex.app == 1">by {{ rex.users }}</b></p>
                            </div>
                            <div class="">

                            </div>
                            <p class="fw-bold h9">
                                Daftar barang
                            </p>
                            <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-650) +'px;'">
                                <table class="table table-hover table-strip table-bordered">
                                    <thead>
                                        <tr class="">
                                            <th width="50px">No. </th>
                                            <th>Nama</th>
                                            <th>Qty</th>
                                            <th>Harga</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="reco.length === 0">
                                            <td colspan="5" class="text-center">Data kosong</td>
                                          </tr>
                                          <tr v-else v-for="(dt, index) in reco" :key="dt.itemid" class="w3-hover-pale-blue w3-small cursor" >
                                            <td width="50px" class="text-center">
                                                &nbsp;{{ index + 1 }}
                                            </td>
                                            <td>
                                              {{ dt.item }}
                                            </td>
                                            <td >
                                              {{ dt.qty }}
                                            </td>
                                            <td >
                                              Rp.{{ dt.harga | toRp }}
                                            </td>
                                            <td >
                                              Rp.{{ dt.total | toRp }}
                                            </td>
                                          </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>

                        </div>
                        <div class="modal-footer"  style="margin-top: 15px;">
                            <h6 class="w3-right">
                                <button form="frmAprove" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Approved</button>
                                <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-add').style.display='none'"><i class="fa fa-close"></i> Close</button>
                            </h6>
                        </div>
                        </div>
                </div>
            </div>

    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "./chart/PieChart.js";
import LineChart from "./chart/LineChart.js";
import moment from "moment";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Main',
    components: {
        PieChart,
        LineChart
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            card : {
                customer : 0,
                supplier : 0,
                invoiceperday : 0,
                amountinvoiceperday : 0,
                invoicepermonth : 0,
                amountinvoicepermonth : 0,
                so : 0,
                si :  0
            },
            rectargetdtl: {},
            recCharts : [],
            chartData: {},
            chartOptions: {},
            chartLabel : [],
            lineData : {},
            lineOptions : {},
            LdtNow : [],
            LdtLast : [],
            LineLabel : [],
            rens : {},
            ren : {},
            red : {},
            reco : {},
            rex : {},
            level : localStorage.dept,
            recust : {},
            departement : 'SPF',
            dept: [
                { text: 'SPF', value: 'SPF' },
                { text: 'Malang', value: 'MLG' },
                { text: 'SWI', value: 'SWI' }
            ]

        }
    },
    methods : {
        changeDept(event){
            this.LoadData();
            this.getDataPie();
            this.getDataLine();
        },
        LoadData(){
            let self = this;
            let param = {
                pfunction : 'selcrontsfl',
                filter : 'main',
                dept: self.departement
            }
            axios.post("dashboard/ApiCront.php",param).then(function (response) {
                self.card.customer = response.data.rec[0].tc_nilai;
                self.card.supplier = response.data.rec[4].tc_nilai;
                self.card.invoiceperday = response.data.rec[2].tc_nilai;
                self.card.amountinvoiceperday = response.data.rec[2].tc_amount;
                self.card.invoicepermonth = response.data.rec[1].tc_nilai;
                self.card.amountinvoicepermonth = response.data.rec[1].tc_amount;
                self.card.so = response.data.rec[3].tc_amount;
                self.card.si = response.data.rec[1].tc_amount;
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },
        setInit() {
            this.LoadData(this.departement);
            this.getDataPie();
            this.getDataLine();
        },
        getDataPie(){
            let self = this;
            let param = {
                pfunction : 'getmostitem',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.recCharts = response.data.rec;
                self.chartData = {
                    labels: response.data.rec.map(entry => entry.kategori),
                    datasets: [
                        {
                            anchor: "center",
                            borderWidth: 0,
                            backgroundColor: response.data.rec.map(entry => self.getColor()),
                            data: response.data.rec.map(entry => entry.total),
                            borderColor: "#FFFFFF",
                            hoverBorderWidth: 5,
                        }
                    ]
                }
            });
            this.chartOptions = {
                maintainAspectRatio: true,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        footer:function (tooltipItem, data) {
                            let sum = 0;
                            let dataset = data.datasets[0];
                            let currentValue = dataset.data[tooltipItem[0].index];
                            let dataArr = dataset.data;
                            dataArr.map(data => {
                                sum += Number(data);
                            });
                            let percentage = (currentValue * 100 / sum).toFixed(2) + '%';
                            return "Persentase "+percentage;
                        }
                    }
                }
            }
        },
        showUn(){
            var self = this;
            self.ren={};
            self.rens = {};
            var dtDetail = document.getElementById('dtDetail');
            dtDetail.classList.add('d-none');
            document.getElementById('modal-un').style.display='block';
            var unapin = document.getElementById('unapin');
                    unapin.classList.add('d-none');
                    document.getElementById('modal-add').style.display='none';

        },
        cekunUp(){
            var self = this;

            self.ren = {
                ta_so : self.ren.ta_so,
                pfunction : 'cekSo'
            };
            axios.post("master/ApiApproved.php",self.ren).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.rens = response.data.ren;
                    var dtDetail = document.getElementById('dtDetail');
                    dtDetail.classList.remove('d-none');
                    var unapin = document.getElementById('unapin');
                    unapin.classList.remove('d-none');
                }else{
                    self.$toast.error(response.data.msg);
                }
            });


        },
        unAp(){
            var self = this;
            console.log('Unapprove button clicked');
            axios.post("master/ApiApproved.php",self.rens).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    document.getElementById('modal-un').style.display='none';
                    var unapin = document.getElementById('unapin');
                    unapin.classList.remove('d-none');
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
        },
        showAdd(){
            let self = this;
            self.red.td_so = '';
            self.rex.cust = '';
            var dtDtl = document.getElementById('dtDtl');
            dtDtl.classList.add('d-none');
            self.reco = '';
            document.getElementById('modal-add').style.display='block';
            document.getElementById('modal-un').style.display='none';
        },
        ceks(){
            console.log(this.username);
            let self = this;

            if (!this.red.td_so || this.red.td_so.trim() === '') {
                alert('Nomor SO tidak boleh kosong!');
                return;
                }

            self.td_so = self.red.td_so;
            axios.post("master/ApiApproved.php",{
                pfunction : 'showitms',
                td_so : self.td_so,

            }).then(function (response) {
                self.reco = response.data.recz;
                if (self.reco.length > 0) {
                        axios.post("master/ApiApproved.php",{
                        pfunction : 'showdtl',
                        td_so : self.td_so,

                    }).then(function (response) {
                        self.rex = response.data.recx;
                    });
                } else {
                    console.log("data kosng")
                }

            });
            var dtDtl = document.getElementById('dtDtl');
                    dtDtl.classList.remove('d-none');
                document.getElementById('modal-form').style.display='block';
                setTimeout(function(){
                    document.getElementById('ta_so').focus();
                },500);
        },
        saveData(event){
            var self = this;
            if (!self.rex.reason || self.rex.reason.trim() === '') {
                alert('Reason tidak boleh kosong!');
                return;
                }
            self.td_so = self.red.td_so;
            if (self.level == '6') {
                self.rex.pfunction = "editjw";
                self.rex.ta_so = self.td_so;
            }else{
                self.rex.pfunction = "edit";
                self.rex.ta_so = self.td_so;
            }
            axios.post("master/ApiApproved.php",self.rex).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    document.getElementById('modal-add').style.display='none';
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        getDataLine(){
            let self = this;
            let param = {
                pfunction : 'selinvoice',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.data = response.data.recNow;
                let rdNow = self.getColor();
                let rdLast = self.getColor();

                self.lineData = {
                    labels: response.data.rec.map(entry => entry.nama),
                    datasets: [
                        {
                            label: new Date().getFullYear(),
                            data: response.data.rec.map(entry => entry.this_year_value),
                            fill: false,
                            backgroundColor : rdNow,
                            borderColor: rdNow,
                            tension: 0.2
                        },
                        {
                            label: new Date().getFullYear()-1,
                            data: response.data.rec.map(entry => entry.last_year_value),
                            fill: false,
                            backgroundColor : rdLast,
                            borderColor: rdLast,
                            tension: 0.2
                        },
                    ],
                }
            });
            self.lineOptions = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(value, index, values) {
                                    return self.toRph(value);
                                }
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'index',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
                                return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
                            });
                        },
                        footer: (ttItem) => {
                            let percen = 0;
                            if (ttItem.length == 1) {
                                percen = 100+'%';
                            }else{
                                let a = parseFloat(ttItem[0]['yLabel']);
                                let b = parseFloat(ttItem[1]['yLabel']);
                                if (a < b) {
                                    percen = ((a-b)/a)*100;
                                }else{
                                    percen = ((a-b)/a)*100;
                                }
                                percen = percen.toFixed(2) + '%';
                            }
                            return "Selisih : "+percen;
                        }
                    }
                }
            }
        },
        getColor(){
            let code = '#' + Math.floor(Math.random() * 16777200).toString(16);
            return code;
        },
        toRph(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
